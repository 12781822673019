import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Layout, notification } from 'antd';
import { getTreeFromFlatData } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import HeaderComponent from '../../components/base/HeaderComponent';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import SettingsStepOrganisation from './SettingsStepOrganisation';
import SettingsStepBrands from './SettingsStepBrands';
import SettingsStepUsers from './SettingsStepUsers';
import SettingsStepContacts from './SettingsStepContacts';

function CompanySettingsBuilder() {
  const [completedStep, setCompletedStep] = useState(0);
  let [counter] = useState(0);
  const currentuser = useContext(UserContext);
  const clientId = currentuser?.data?.client?._id;
  const [client, setClient] = useState({});
  const [step, setStep] = useState(1);
  const [branches, setBranches] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandForEdit, setBrandForEdit] = useState(null);
  const [users, setUsers] = useState([]);
  const [userForEdit, setUserForEdit] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [contactForEdit, setContactForEdit] = useState(null);
  const [uploadPercentageBrands, setUploadPercentageBrands] = useState(0);
  const [uploadPercentageUsers, setUploadPercentageUsers] = useState(0);
  const [uploadPercentageContacts, setUploadPercentageContacts] = useState(0);
  const [errorBrands, setErrorBrands] = useState(false);
  const [fileNameBrands, setFileNameBrands] = useState("");
  const [fileNameContacts, setFileNameContacts] = useState("");
  const [fileNameUsers, setFileNameUsers] = useState("");
  const [errorUsers, setErrorUsers] = useState(false);
  const [errorContacts, setErrorContacts] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorBrandResolved, setErrorBrandResolved] = useState(false);
  const [errorUserResolved, setErrorUserResolved] = useState(false);
  const [errorContactResolved, setErrorContactResolved] = useState(false);
  let downloadFinished = [false, false, false];
  const [inProgress, setInProgress] = useState([true, true, true]);
  let downloadError = [false, false, false];
  const brandColumns = ['name', 'therapeuticArea', 'therapeuticAreaCondition'];
  const userColumns = ['email', 'firstName', 'lastName', 'role', 'brand', 'jobFunction', 'clientBranch.branchName'];
  const contactColumns = ['firstName','lastName', 'employer', 'typeHCP', 'primarySpeciality.name', 'secondarySpeciality.name', 'phone', 'email'];


  // eslint-disable-next-line consistent-return
  const sortDataForTree = (data) => {
    try {
      const formatedData = getTreeFromFlatData({
        flatData: data.map((node) => ({ ...node, title: node.branchName, key: node._id })),
        getKey: (node) => node._id, // resolve a node's key
        getParentKey: (node) => node.parentBranch, // resolve a node's parent's key
        rootKey: null // The value of the parent key when there is no parent (i.e., at root level)
      });
      return formatedData;
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-branches`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data?.items) {
        // If there are branches, increase the completed step by 1
        if (response.data.items.length > 0) setCompletedStep(++counter);
        const data = sortDataForTree(response.data.items);
        setBranches(response.data.items);
        setTreeData(data);
      } else setTreeData([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getClient = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/clients/${clientId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data) {
        setClient(response.data);
      } else setClient({});
    } catch (error) {
      console.log(error);
    }
  };

  const getBrands = async () => {
    const response = await Axios.get(`${SERVER_URL}/brands`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    });
    if (response?.data?.items) {
      // If there are brands, increase the completed step by 1
      if (response.data.items.length > 0) setCompletedStep(++counter);
      setBrands(response.data.items);
    } else setBrands([]);
    if(!checkEmptyField(response.data.items, brandColumns)){
      setErrorBrands(false);
      if(brandForEdit) setErrorBrandResolved(true);
    } else {
      setErrorBrands(true);
    }
  };

  const getBrandForEdit = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/brands/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    });
    if (response?.data) {
      setBrandForEdit(response.data);
    } else setBrandForEdit(null);
  };

  const getUsers = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-users`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data?.items) {
        // If there are users, increase the completed step by 1
        if (response.data.items.length > 0) setCompletedStep(++counter);
        setUsers(response.data.items);
      } else setUsers([]);

      if(checkEmptyField(response.data.items, userColumns)){
        setErrorUsers(true);
      } else {
        setErrorUsers(false);
        if(userForEdit) setErrorUserResolved(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data) {
        setUserForEdit(response.data);
      } else setUserForEdit(null);
    } catch (error) {
      console.log(error);
    }
  };

  const getContacts = async () => {
    const response = await Axios.get(`${SERVER_URL}/contacts`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    });
    if (response?.data?.items) {
      // If there are contacts, increase the completed step by 1
      if (response.data.items.length > 0) setCompletedStep(++counter);
      setContacts(response.data.items);
    } else setContacts([]);
    if (checkEmptyField(response.data.items, contactColumns)){
      setErrorContacts(true);
    } else {
      setErrorContacts(false);
      if(contactForEdit) setErrorContactResolved(true);
    }
  };

  const getContactForEdit = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/contacts/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    });
    if (response?.data) {
      setContactForEdit(response.data);
    } else setContactForEdit(null);
  };

  useEffect(() => {
    getClient();
    getBranches();
    getBrands();
    getContacts();
    getUsers();
  }, [
    setClient,
    setTreeData,
    setUsers,
    setUserForEdit,
    setBrandForEdit,
    setContactForEdit,
    setCompletedStep
  ]);

  const clientData = client?._id ? client : undefined;

  const changeCompanyLevels = async (levelNumber, level1, level2, level3, id) => {
    const data = { levelNumber, level1, level2, level3, id };
    try {
      await Axios.put(`${SERVER_URL}/change-client-organisation-level`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Company levels successfully updated',
        placement: 'topLeft',
        duration: 2
      });
      getClient();
      getBranches();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const treeDataChangeHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew
      ? `${SERVER_URL}/client-branches`
      : `${SERVER_URL}/client-branches/${formData.id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: `Branch ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2
      });
      getBranches();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const treeBranchMoveHandler = async (data) => {
    const changedBranch = data.node || null;
    const parentBranch = data.nextParentNode || null;
    if (parentBranch && changedBranch.parentBranch !== parentBranch._id) {
      try {
        changedBranch.parentBranch = parentBranch._id;
        await Axios.put(`${SERVER_URL}/client-branches/${changedBranch._id}`, changedBranch, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        getBranches();
        notification.success({
          message: 'Branch relocated successfully',
          placement: 'topLeft',
          duration: 2
        });
      } catch (error) {
        console.log(error);
        notification.error({
          message: error?.response?.data?.message,
          placement: 'topLeft',
          duration: 2
        });
      }
    }
  };

  const treeDeleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/client-branches/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Branch deleted successfully',
        placement: 'topLeft',
        duration: 2
      });
      getBranches();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const addAndEditBrandHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/brands` : `${SERVER_URL}/brands/${brandForEdit._id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: `Brand ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2
      });
      setBrandForEdit(null);
      getBrands();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const deleteBrandHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/brands/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Brand deleted successfully',
        placement: 'topLeft',
        duration: 2
      });
      getBrands();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const addAndEditClientUserHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/register` : `${SERVER_URL}/users/${userForEdit._id}`;
    try {
      await Axios[method](
        route,
        { ...formData, client: clientId },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      notification.success({
        message: `User ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2
      });
      setUserForEdit(null);
      getClient();
      getUsers();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const deleteClientUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'User deleted successfully',
        placement: 'topLeft',
        duration: 2
      });
      getClient();
      getUsers();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const addAndEditContactHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/contacts` : `${SERVER_URL}/contacts/${contactForEdit._id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: `Contact ${isNew ? 'Added' : 'Updated'} successfully`,
        placement: 'topLeft',
        duration: 2
      });
      setContactForEdit(null);
      getContacts();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const deleteContactHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/contacts/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      getContacts();
      notification.success({
        message: 'Contact deleted successfully',
        placement: 'topLeft',
        duration: 2
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const bulkAddBrandsHandler = async (file) => {
    try {
      setUploadPercentageBrands(0);
      setFileNameBrands(file.name)
      const formData = new FormData();
      formData.append('file', file);
      await Axios.post(`${SERVER_URL}/brands-bulk-add`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}`, 'Content-Type': 'multipart/form-data' }, onUploadProgress: async() => {
          for (let i = 0; i <= 100; i += 1) {
            if(!downloadFinished[0]) {
              await new Promise(resolve => setTimeout(resolve, 100));
              setUploadPercentageBrands(i);
            } else if (i >= 90){
              await new Promise(resolve => setTimeout(resolve, 100));
              setUploadPercentageBrands(i);
            } else if (downloadError[0]) {
              break;
            } else {
              i = 90;
            }
          }
        },
        onDownloadProgress: async () => {
          const progress = [...inProgress];
          progress[0] = false;
          setInProgress(progress);
          downloadFinished[0] = true;
        }
      });

      notification.success({
        message: 'Brands and added successfully',
        placement: 'topLeft',
        duration: 2
      });
      getBrands();
    } catch (error) {
      console.log(error);
      downloadError[0] = true;
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const validateBulkUploadData = async (data) => {
    try {
      const response = await Axios.post(`${SERVER_URL}/validate-bulk-upload-user-data`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      return response?.data;
    } catch (error) {
      console.log('Error in validateBulkUploadData', error);
    }
  };

  const checkEmptyField = (data, fields) => {
   let emptyField = false;
    for(let i = 0; i < data.length; i++) {
      for(let j = 0; j < fields.length; j++){
          const fieldValue = fields[j].includes('.') ? fields[j].split('.').reduce((acc, key) => acc && acc[key], data[i]) : data[i][fields[j]];
          if(!fieldValue || Array.isArray(fieldValue) && !fieldValue.length){
            emptyField = true;
            break;
          } else {
            continue;
          }
        }
        if (emptyField) break;
    }
    return emptyField;
  }

  const bulkAddUsersHandler = async (file) => {
    try {
      setFileNameUsers(file.name);
      setUploadPercentageUsers(0);
      const formData = new FormData();
      formData.append('file', file);
      const response = await Axios.post(`${SERVER_URL}/users-bulk-add`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}`, 'Content-Type': 'multipart/form-data'  }, onUploadProgress: async () => {
          for (let i = 0; i <= 100; i += 1) {
            if(!downloadFinished[1]) {
              await new Promise(resolve => setTimeout(resolve, 100));
              setUploadPercentageUsers(i);
            } else if (i >= 90){
              await new Promise(resolve => setTimeout(resolve, 100));
              setUploadPercentageUsers(i);
            } else if (downloadError[1]) {
              break;
            } else {
              i = 90;
            }
          }
        }, onDownloadProgress: async () => {
          const progress = [...inProgress];
          progress[1] = false;
          setInProgress(progress);
          downloadFinished[1] = true;
        }
      });
      notification.success({
        message: 'Users added successfully',
        placement: 'topLeft',
        duration: 2
      });
      console.log('Bulk user response', response);
      await getClient();
      await getUsers();
    } catch (error) {
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      }
      downloadError[1] = true;
      notification.error({
        message: error?.response?.data?.message || error.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const bulkAddContactsHandler = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      setFileNameContacts(file.name);
      await Axios.post(`${SERVER_URL}/contacts-bulk-add`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}`, 'Content-Type': 'multipart/form-data' }, onUploadProgress: async () => {
          for (let i = 0; i <= 100; i += 1) {
            if(!downloadFinished[2]) {
              await new Promise(resolve => setTimeout(resolve, 100));
              setUploadPercentageContacts(i);
            } else if (i >= 90){
              await new Promise(resolve => setTimeout(resolve, 100));
              setUploadPercentageContacts(i);
            } else if (downloadError[2]) {
              break;
            } else {
              i = 90;
            }
          }
        }, onDownloadProgress: async () => {
          const progress = [...inProgress];
          progress[2] = false;
          setInProgress(progress);
          downloadFinished[2] = true;
        }
      });
      notification.success({
        message: 'Contacts added successfully',
        placement: 'topLeft',
        duration: 2
      });
      getContacts();
    } catch (error) {
      console.log(error);
      downloadError[2] = true;
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const downloadProductTemplateHandler = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/bulk-upload-product-template`, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Product_Upload_Template.xlsx');
      link.click();
      link.href = '';
      notification.success({ message: 'Template downloaded.', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const downloadUserTemplateHandler = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/bulk-upload-user-template`, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'User_Upload_Template.xlsx');
      link.click();
      link.href = '';
      notification.success({ message: 'Template downloaded.', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const downloadContactsTemplateHandler = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/bulk-upload-contact-template`, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Contact_Upload_Template.xlsx');
      link.click();
      link.href = '';
      notification.success({ message: 'Template downloaded.', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Welcome to your Qoolo® Company Settings" />
        <div className="company-settings-builder">
          {step === 1 && clientData && clientData.companyName && treeData && (
            <SettingsStepOrganisation
              step={step}
              setStep={setStep}
              client={clientData}
              treeData={treeData}
              treeDataChangeHandler={treeDataChangeHandler}
              treeBranchMoveHandler={treeBranchMoveHandler}
              treeDeleteDataHandler={treeDeleteDataHandler}
              setTreeData={setTreeData}
              changeCompanyLevels={changeCompanyLevels}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
            />
          )}
          {step === 2 && brands && (
            <SettingsStepBrands
              step={step}
              setStep={setStep}
              SERVER_URL={SERVER_URL}
              brands={brands}
              getBrandForEdit={getBrandForEdit}
              setBrandForEdit={setBrandForEdit}
              brandForEdit={brandForEdit}
              deleteBrandHandler={deleteBrandHandler}
              addAndEditBrandHandler={addAndEditBrandHandler}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
              bulkAddBrandsHandler={bulkAddBrandsHandler}
              downloadProductTemplateHandler={downloadProductTemplateHandler}
              uploadPercentage={uploadPercentageBrands}
              setUploadPercentage={setUploadPercentageBrands}
              setError={setErrorBrands}
              error={errorBrands}
              fileName={fileNameBrands}
              brandColumns={brandColumns}
              errorResolved={errorBrandResolved}
              setErrorResolved={setErrorBrandResolved}
              inProgress={inProgress[0]}
            />
          )}
          {step === 3 && brands && branches && users && clientData && clientData.companyName && (
            <SettingsStepUsers
              step={step}
              setStep={setStep}
              client={clientData}
              brands={brands}
              branches={branches}
              users={users}
              getUserForEdit={getUserForEdit}
              setUserForEdit={setUserForEdit}
              userForEdit={userForEdit}
              deleteClientUserHandler={deleteClientUserHandler}
              addAndEditClientUserHandler={addAndEditClientUserHandler}
              validateBulkUploadData={validateBulkUploadData}
              bulkAddUsersHandler={bulkAddUsersHandler}
              downloadUserTemplateHandler={downloadUserTemplateHandler}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
              uploadPercentage={uploadPercentageUsers}
              setUploadPercentage={setUploadPercentageUsers}
              setError={setErrorUsers}
              error={errorUsers}
              fileName={fileNameUsers}
              userColumns={userColumns}
              errorResolved={errorUserResolved}
              setErrorResolved={setErrorUserResolved}
              errors={errors}
              inProgress={inProgress[1]}
              setErrors={setErrors}
            />
          )}
          {step === 4 && (
            <SettingsStepContacts
              step={step}
              setStep={setStep}
              SERVER_URL={SERVER_URL}
              contacts={contacts}
              getContactForEdit={getContactForEdit}
              setContactForEdit={setContactForEdit}
              contactForEdit={contactForEdit}
              deleteContactHandler={deleteContactHandler}
              addAndEditContactHandler={addAndEditContactHandler}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
              bulkAddContactsHandler={bulkAddContactsHandler}
              downloadContactsTemplateHandler={downloadContactsTemplateHandler}
              uploadPercentage={uploadPercentageContacts}
              setUploadPercentage={setUploadPercentageContacts}
              setError={setErrorContacts}
              error={errorContacts}
              fileName={fileNameContacts}
              contactColumns={contactColumns}
              errorResolved={errorContactResolved}
              setErrorResolved={setErrorContactResolved}
              inProgress={inProgress[2]}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default CompanySettingsBuilder;
