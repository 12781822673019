/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import { Input, Button, Form, Select, Checkbox, Switch, Row, Col, Tooltip } from 'antd';
import { ReactComponent as Warning } from "../../icons/warning.svg";

const { Option } = Select;

const formInit = {
  _id: null
};

const ClientUserForm = ({
  createHandler,
  updateHandler,
  isNew,
  branchs,
  data,
  brands,
  createProcess = false,
  client,
  availableCreatorLicences,
  availablePublisherLicences
}) => {
  const [form] = Form.useForm();
  if (data && data.clientBranch && data.clientBranch._id) data.clientBranch = data.clientBranch._id;
  const [disabled, setDisabled] = useState(true);
  const initialValues = { ...formInit, ...data };
  const [stateForRender, setStateForRender] = useState(0);
  

  const onFinish = (values) => {
    if (isNew) createHandler(values, true);
    else updateHandler(values, false);
    if(isNew) form.resetFields();
  };
  const onRoleChange = (value) => {
    form.setFieldsValue({ role: value });
    if (value !== 'creator') {
      form.setFieldValue('canCreateThemes', false);
    }
  };
  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);

  useEffect(() => {
    if(!form.getFieldsValue().firstName || !form.getFieldsValue().lastName || !form.getFieldsValue().role || !form.getFieldsValue().phone || !form.getFieldsValue().jobFunction){
      setDisabled(true)
    } else {
      setDisabled(false);
    }
  }, [form.getFieldsValue()])

  console.log('client', client);

  return (
    <div className="panel">
      <div className={!createProcess ? 'modal-team' : undefined}>
      <Form
          initialValues={initialValues}
          onValuesChange={(changedValues, values) => {
            if(!values.firstName || !values.lastName || !values.role || !values.phone || !values.jobFunction){
              setDisabled(true)
            } else {
              setDisabled(false);
            }
            setStateForRender((prev) => prev + 1);
          }}
          onFinish={(values) => onFinish(values, !data)}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          form={form}
          colon={false}
        >
          {!createProcess && (
            <div
              className="modal-team__header"
              style={{ textAlign: 'center', marginBottom: '30px' }}
            >
              <h2>{isNew ? 'Add new' : 'Edit'} User</h2>
            </div>
          )}
          {isNew ? <div className="modal-team__input">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: 'Please input your First name!' }]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your Last name!' }]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            {/*{isNew ? (*/}
            <Row gutter={16}>

              {/* {isNew && ( */}
              <Col span={12}>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter your Email!' },
                    {
                      validator: (_, value) => {
                        if (value) {
                          const reg = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
                          if (!reg.test(value))
                            return Promise.reject(new Error('Please enter valid email address!'));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col span={12}>


                <Form.Item
                  name="phone"
                  rules={[
                    { required: false, message: 'Please enter phone number!' },
                    {
                      validator: (_, value) => {
                        if (value) {
                          const reg = /^[0-9+]{8,}$/g;
                          if (!reg.test(value))
                            return Promise.reject(new Error('Please enter valid phone number!'));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <Input placeholder="Phone" />

                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {isNew ? (
                <Col span={12}>
                  <Form.Item name="role" rules={[{ required: true, message: 'Please select role!' }]}>
                    <Select

                      onChange={(value) => {
                        onRoleChange(value)
                      }}
                      placeholder="Role"
                      className="conditions-select dropdown-small"
                    >
                      <Option value="creator">Creator</Option>
                      <Option value="publisher">Publisher</Option>
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={12}>
                  <Form.Item name="role">
                    <Select disabled>
                      <Option value="creator">Creator</Option>
                      <Option value="publisher">Publisher</Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item name="brand" rules={[{ required: false }]}>
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Brands"
                    className="conditions-select dropdown-small"
                    maxTagCount={1}
                    filterSort={(optionA, optionB) => optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())}
                  >
                    {brands &&
                      brands.map((brand) => (
                        <Option key={brand._id} value={brand._id}>
                          {brand.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* Show this only if selected role is creator */}
            {/*<Row justify='space-between'>*/}
            {/*  <Col span={12}>*/}
            {/*    <Form.Item*/}
            {/*        noStyle*/}
            {/*        shouldUpdate={(prevValues, currentValues) =>*/}
            {/*            prevValues.role !== currentValues.role ||*/}
            {/*            prevValues.canCreateThemes !== currentValues.canCreateThemes*/}
            {/*        }*/}
            {/*    >*/}
            {/*      {({ getFieldValue, setFieldValue }) => {*/}
            {/*        const role = getFieldValue('role');*/}
            {/*        const checked = getFieldValue('canCreateThemes');*/}
            {/*        if (role?.includes('creator')) {*/}
            {/*          return (*/}
            {/*              <Form.Item name="canCreateThemes" valuePropName="checked">*/}
            {/*                <Switch*/}
            {/*                    checked={checked}*/}
            {/*                    onChange={(value) => setFieldValue('canCreateThemes', value)}*/}
            {/*                />*/}
            {/*                <span style={{ marginLeft: '15px' }}>Can create Themes</span>*/}
            {/*              </Form.Item>*/}
            {/*          );*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    </Form.Item>*/}
            {/*  </Col>*/}

            {/* {client?.isAgency &&*/}
            {/*      <Col span={12}>*/}
            {/*        <Form.Item*/}
            {/*            noStyle*/}
            {/*            shouldUpdate={(prevValues, currentValues) =>*/}
            {/*                prevValues.role !== currentValues.role ||*/}
            {/*                prevValues.isAgent !== currentValues.isAgent*/}
            {/*            }*/}
            {/*        >*/}
            {/*          {({ getFieldValue, setFieldValue }) => {*/}
            {/*            const role = getFieldValue('role');*/}
            {/*            const checked = getFieldValue('isAgent');*/}
            {/*            if (role?.includes('creator')) {*/}
            {/*              return (*/}
            {/*                  <Form.Item name="isAgent" valuePropName="checked">*/}
            {/*                    <Switch*/}
            {/*                        checked={checked}*/}
            {/*                        onChange={(value) => setFieldValue('isAgent', value)}*/}
            {/*                    />*/}
            {/*                    <span style={{ marginLeft: '15px' }}>User is agent</span>*/}
            {/*                  </Form.Item>*/}
            {/*              );*/}
            {/*            }*/}
            {/*          }}*/}
            {/*        </Form.Item>*/}
            {/*      </Col>*/}
            {/*  } */}
            {/*</Row>*/}
            {/*<Row gutter={16}>*/}
            {/*  <Col span={12}>*/}
            {/*    <Form.Item*/}
            {/*        name="jobFunction"*/}
            {/*        rules={[{ required: true, message: 'Please select job description!' }]}*/}
            {/*    >*/}
            {/*      <Select placeholder="Department" className="conditions-select dropdown-small">*/}
            {/*        <Option value="IT / Data / Tools">IT / Data / Tools</Option>*/}
            {/*        <Option value="Executive">Executive</Option>*/}
            {/*        <Option value="Medical Affairs">Medical Affairs</Option>*/}
            {/*        <Option value="Sales / Commercial">Sales / Commercial</Option>*/}
            {/*        <Option value="Market Access">Market Access</Option>*/}
            {/*        <Option value="Communications">Communications</Option>*/}
            {/*        <Option value="Clinical">Clinical</Option>*/}
            {/*        <Option value="Other">Other</Option>*/}
            {/*      </Select>*/}
            {/*    </Form.Item>*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*<Form.Item name="clientBranch" rules={[{ required: false }]}>*/}
            {/*  <Select placeholder="Site" className="conditions-select dropdown-small">*/}
            {/*    {branchs &&*/}
            {/*        branchs.map((branch) => (*/}
            {/*            <Option key={branch._id} value={branch._id}>*/}
            {/*              {branch.branchName}*/}
            {/*            </Option>*/}
            {/*        ))}*/}
            {/*  </Select>*/}
            {/*</Form.Item>*/}
            {/*  </Col>*/}
            {/*</Row>*/}

            {/*<Form.Item*/}
            {/*    noStyle*/}
            {/*    shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}*/}
            {/*    style={{ display: 'flex', flexDirection: 'row', flexFlow: 'nowrap' }}*/}
            {/*>*/}
            {/*  {({ getFieldValue }) => {*/}
            {/*    const role = getFieldValue('role');*/}
            {/*    const isFirstRoleType =*/}
            {/*        role === 'publisher' || (Array.isArray(role) && role[0] === 'publisher');*/}
            {/*    const isSecondRoleType =*/}
            {/*        role === 'creator' || (Array.isArray(role) && role[0] === 'creator');*/}
            {/*    const licenses = {*/}
            {/*      publisher: availablePublisherLicences,*/}
            {/*      creator: availableCreatorLicences*/}
            {/*    };*/}
            {/*    const licenseNames = {*/}
            {/*      publisher: 'Publisher',*/}
            {/*      creator: 'Creator'*/}
            {/*    };*/}

            {/*    if (isFirstRoleType || isSecondRoleType) {*/}
            {/*      const currentRole = isFirstRoleType ? 'publisher' : 'creator';*/}
            {/*      const availableLicences = licenses[currentRole];*/}
            {/*      const licenseName = licenseNames[currentRole];*/}

            {/*      if (availableLicences > 0) {*/}
            {/*        return (*/}
            {/*            <Form.Item*/}
            {/*                name="licenceActive"*/}
            {/*                valuePropName="checked"*/}
            {/*                label={`${licenseName} Licenses: ${availableLicences}`}*/}
            {/*            >*/}
            {/*              <Checkbox disabled={availableLicences < 1}>*/}
            {/*                {`Activate ${licenseName} License`}*/}
            {/*              </Checkbox>*/}
            {/*            </Form.Item>*/}
            {/*        );*/}
            {/*      }*/}
            {/*      return (*/}
            {/*          <p>{`You don't have enough ${licenseName} licences, please contact Qoolo support.`}</p>*/}
            {/*      );*/}
            {/*    }*/}
            {/*  }}*/}
            {/*</Form.Item>*/}

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="jobFunction"
                  rules={[{ required: true, message: 'Please select job description!' }]}
                >
                  <Select placeholder="Department" className="conditions-select dropdown-small">
                    <Option value="IT / Data / Tools">IT / Data / Tools</Option>
                    <Option value="Executive">Executive</Option>
                    <Option value="Medical Affairs">Medical Affairs</Option>
                    <Option value="Sales / Commercial">Sales / Commercial</Option>
                    <Option value="Market Access">Market Access</Option>
                    <Option value="Communications">Communications</Option>
                    <Option value="Clinical">Clinical</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="clientBranch" rules={[{ required: false }]}>
                  <Select placeholder="Site" className="conditions-select dropdown-small">
                    {branchs &&
                      branchs.map((branch) => (
                        <Option key={branch._id} value={branch._id}>
                          {branch.branchName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.role !== currentValues.role ||
                    prevValues.canCreateThemes !== currentValues.canCreateThemes
                  }
                >
                  {({ getFieldValue, setFieldValue }) => {
                    const role = getFieldValue('role');
                    const checked = getFieldValue('canCreateThemes');
                    if (role === 'creator' || (role && role[0] && role[0] === 'creator')) {
                      return (
                        <Form.Item name="canCreateThemes" valuePropName="checked">
                          <Switch
                            size='default'
                            checked={checked}
                            onChange={(value) => setFieldValue('canCreateThemes', value)}
                          />
                          <span style={{ marginLeft: '5px', fontSize: '14px' }}>User can create Themes</span>
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
              </Col>



              {client?.isAgency &&
                <Col span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.role !== currentValues.role ||
                      prevValues.isAgent !== currentValues.isAgent
                    }
                  >
                    {({ getFieldValue, setFieldValue }) => {
                      const role = getFieldValue('role');
                      const checked = getFieldValue('isAgent');
                      if (role?.includes('creator')) {
                        return (
                          <Form.Item name="isAgent" valuePropName="checked">
                            <Switch
                              checked={checked}
                              onChange={(value) => setFieldValue('isAgent', value)}
                            />
                            <span style={{ marginLeft: '15px' }}>User is agent</span>
                          </Form.Item>
                        );
                      }
                    }}
                  </Form.Item>
                </Col>


              }
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}
                >
                  {({ getFieldValue }) => {
                    const role = getFieldValue('role');
                    const isFirstRoleType =
                      role === 'publisher' || (Array.isArray(role) && role[0] === 'publisher');
                    const isSecondRoleType =
                      role === 'creator' || (Array.isArray(role) && role[0] === 'creator');
                    const licenses = {
                      publisher: availablePublisherLicences,
                      creator: availableCreatorLicences
                    };
                    const licenseNames = {
                      publisher: 'Publisher',
                      creator: 'Creator'
                    };

                    if (isFirstRoleType || isSecondRoleType) {
                      const currentRole = isFirstRoleType ? 'publisher' : 'creator';
                      const availableLicences = licenses[currentRole];
                      const licenseName = licenseNames[currentRole];
                      const checked = getFieldValue('licenceActive');
                      if (availableLicences >= 0) {
                        return (
                          <Form.Item
                            name="licenceActive"
                            valuePropName="checked"
                            style={{ display: 'flex', alignItems: 'flex-start' }}
                          >
                            <Switch disabled={availableLicences < 1} checked={checked} />

                            <span style={{ textAlign: 'left', marginLeft: '5px' }}>
                              {`Activate a ${licenseName} License`}
                            </span>
                            {availableLicences === 0 && <Tooltip
                              color="#FB5E5E"
                              placement="bottom"
                              title={
                                <div className="homebase__tooltip">
                                  You have already assigned all available Creator Licenses. Please contact us at support@qoolo.com to acquire additional licenses or free up Licenses by editing the table on the right side.
                                </div>
                              }
                            >
                              <div style={{ marginLeft: '8px', alignContent: 'center', padding: '3px' }}>
                                <Warning />
                              </div>
                            </Tooltip>}
                          </Form.Item>
                        )
                      }
                      return (
                        <p>{`You don't have enough ${licenseName} licences, please contact Qoolo support.`}</p>
                      );
                    }
                  }}
                </Form.Item>
              </Col>
              <Col span={12}
              >
                <Form.Item
                  noStyle
                  shouldUpdate
                >
                  {({ getFieldValue }) => {
                    const role = getFieldValue('role');
                    const isFirstRoleType =
                      role === 'publisher' || (Array.isArray(role) && role[0] === 'publisher');
                    const isSecondRoleType =
                      role === 'creator' || (Array.isArray(role) && role[0] === 'creator');
                    const licenses = {
                      publisher: availablePublisherLicences,
                      creator: availableCreatorLicences
                    };
                    const licenseNames = {
                      publisher: 'Publisher',
                      creator: 'Creator'
                    };
                    if (isFirstRoleType || isSecondRoleType) {
                      const currentRole = isFirstRoleType ? 'publisher' : 'creator';
                      const availableLicences = licenses[currentRole];
                      const licenseName = licenseNames[currentRole];
                      return (
                        <Form.Item>
                          <span style={{ fontSize: '14px' }}>{`Remaining ${licenseName} Licenses: ${availableLicences}`}</span>
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>
          </div> :

            <div className="modal-team__input">

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.firstName !== curValues.firstName
                    }
                    name="firstName"
                    rules={[{ required: true, message: 'Please input your First name!' }]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.lastName !== curValues.lastName
                    }
                    name="lastName"
                    rules={[{ required: true, message: 'Please input your Last name!' }]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="email"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.email !== curValues.email
                }
                rules={[
                  { required: true, message: 'Please enter your Email!' },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const reg = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
                        if (!reg.test(value))
                          return Promise.reject(new Error('Please enter valid email address!'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="phone"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.phone !== curValues.phone
                }
                rules={[
                  { required: false, message: 'Please enter phone number!' },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const reg = /^[0-9+]{8,}$/g;
                        if (!reg.test(value))
                          return Promise.reject(new Error('Please enter valid phone number!'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Phone" />

              </Form.Item>
              <Form.Item shouldUpdate={(prevValues, curValues) =>
                prevValues.role !== curValues.role
              } name="role">
                <Select disabled>
                  <Option value="creator">Creator</Option>
                  <Option value="publisher">Publisher</Option>
                </Select>
              </Form.Item>
              <Form.Item name="brand" rules={[{ required: false }]}>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Brands"
                  className="conditions-select dropdown-small"
                  maxTagCount={1}
                  filterSort={(optionA, optionB) => optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())}
                >
                  {brands &&
                    brands.map((brand) => (
                      <Option key={brand._id} value={brand._id}>
                        {brand.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {/*<Form.Item name="brand" rules={[{ required: false }]}>*/}
              {/*  <Select*/}
              {/*    mode="multiple"*/}
              {/*    allowClear*/}
              {/*    placeholder="Brands"*/}
              {/*    className="conditions-select dropdown-small"*/}
              {/*    maxTagCount={1}*/}
              {/*    filterSort={(optionA, optionB) => optionA.children*/}
              {/*      .toLowerCase()*/}
              {/*      .localeCompare(optionB.children.toLowerCase())}*/}
              {/*  >*/}
              {/*    {brands &&*/}
              {/*      brands.map((brand) => (*/}
              {/*        <Option key={brand._id} value={brand._id}>*/}
              {/*          {brand.name}*/}
              {/*        </Option>*/}
              {/*      ))}*/}
              {/*  </Select>*/}
              {/*</Form.Item>*/}
              <Form.Item
                name="jobFunction"
                rules={[{ required: true, message: 'Please select job description!' }]}
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.jobFunction !== curValues.jobFunction
                }
              >
                <Select placeholder="Department" className="conditions-select dropdown-small">
                  <Option value="IT / Data / Tools">IT / Data / Tools</Option>
                  <Option value="Executive">Executive</Option>
                  <Option value="Medical Affairs">Medical Affairs</Option>
                  <Option value="Sales / Commercial">Sales / Commercial</Option>
                  <Option value="Market Access">Market Access</Option>
                  <Option value="Communications">Communications</Option>
                  <Option value="Clinical">Clinical</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item name="clientBranch" rules={[{ required: false }]}>
                <Select placeholder="Site" className="conditions-select dropdown-small">
                  {branchs &&
                    branchs.map((branch) => (
                      <Option key={branch._id} value={branch._id}>
                        {branch.branchName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.role !== currentValues.role ||
                  prevValues.canCreateThemes !== currentValues.canCreateThemes
                }
              >
                {({ getFieldValue, setFieldValue }) => {
                  const role = getFieldValue('role');
                  const checked = getFieldValue('canCreateThemes');
                  if (role === 'creator' || (role && role[0] && role[0] === 'creator')) {
                    return (
                      <Form.Item name="canCreateThemes" valuePropName="checked">
                        <Switch
                          size='default'
                          checked={checked}
                          onChange={(value) => setFieldValue('canCreateThemes', value)}
                        />
                        <span style={{ marginLeft: '5px', fontSize: '14px' }}>User can create Themes</span>
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role || prevValues.licenceActive !== currentValues.licenceActive}
              >
                {({ getFieldValue, setFieldValue }) => {
                  const role = getFieldValue('role');
                  const isFirstRoleType =
                    role === 'publisher' || (Array.isArray(role) && role[0] === 'publisher');
                  const isSecondRoleType =
                    role === 'creator' || (Array.isArray(role) && role[0] === 'creator');
                  const licenses = {
                    publisher: availablePublisherLicences,
                    creator: availableCreatorLicences
                  };
                  const licenseNames = {
                    publisher: 'Publisher',
                    creator: 'Creator'
                  };

                  if (isFirstRoleType || isSecondRoleType) {
                    const currentRole = isFirstRoleType ? 'publisher' : 'creator';
                    const availableLicences = licenses[currentRole];
                    const licenseName = licenseNames[currentRole];
                    const checked = getFieldValue('licenceActive');
                    if (availableLicences >= 0) {
                      return (
                        <Form.Item
                          name="licenceActive"
                          valuePropName="checked"
                          style={{ display: 'flex', alignItems: 'flex-start' }}

                        >
                          <Switch onChange={(value) => {
                            setFieldValue('licenceActive', value)
                          }} disabled={availableLicences < 1} checked={checked} />

                          <span style={{ textAlign: 'left', marginLeft: '5px' }}>
                            {`Activate a ${licenseName} License`}
                          </span>
                          {/*{availableLicences === 0 && <Tooltip*/}
                          {/*    color="#FB5E5E"*/}
                          {/*    placement="bottom"*/}
                          {/*    title={*/}
                          {/*      <div className="homebase__tooltip">*/}
                          {/*        You have already assigned all available Creator Licenses. Please contact us at support@qoolo.com to acquire additional licenses or free up Licenses by editing the table on the right side.*/}
                          {/*      </div>*/}
                          {/*    }*/}
                          {/*>*/}
                          {/*  <div style={{ marginLeft: '8px', alignContent: 'center', padding: '3px' }}>*/}
                          {/*    <Warning />*/}
                          {/*  </div>*/}
                          {/*</Tooltip>}*/}
                        </Form.Item>
                      )
                    }
                    return (
                      <p>{`You don't have enough ${licenseName} licences, please contact Qoolo support.`}</p>
                    );
                  }
                }}
              </Form.Item>
              {client?.isAgency &&

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.role !== currentValues.role ||
                    prevValues.isAgent !== currentValues.isAgent
                  }
                >
                  {({ getFieldValue, setFieldValue }) => {
                    const role = getFieldValue('role');
                    const checked = getFieldValue('isAgent');
                    if (role?.includes('creator')) {
                      return (
                        <Form.Item name="isAgent" valuePropName="checked">
                          <Switch
                            checked={checked}
                            onChange={(value) => setFieldValue('isAgent', value)}
                          />
                          <span style={{ marginLeft: '15px' }}>User is agent</span>
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>


              }
            </div>

          }
          <div>
            <Button
              id="company-settings_add-user"
              type="primary"
              htmlType="submit"
              style={{ margin: '15px 0px', width: !isNew ? '100%' : 'auto' }}
              disabled={disabled}
            >
              {isNew ? 'Add' : 'Edit'} User
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ClientUserForm;
