/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, InputNumber, Input, Divider, Tooltip } from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as InfoCircleOutlined } from "../../icons/info icon new.svg";
import StepNavigation from './StepNavigation';
import ClientBranches from './ClientBranches';

const SettingsStepOrganisation = ({
  step,
  setStep,
  completedStep,
  setCompletedStep,
  client,
  changeCompanyLevels,
  treeData,
  setTreeData,
  treeDataChangeHandler,
  treeBranchMoveHandler,
  treeDeleteDataHandler
}) => {
  const [levelNumber, setLevelNumber] = useState(
    client?.organisationLevels ? client.organisationLevels : 1
  );
  const [level1, setLevel1] = useState(client?.level1 ? client.level1 : 'Company_Name');
  const [level2, setLevel2] = useState(client?.level2 ? client.level2 : 'Sites');
  const [level3, setLevel3] = useState(client?.level3 ? client.level3 : 'Country');

  return (
    <div className="company-settings-builder__step-1">
      <div className="company-settings-builder__left-side">
        <p className="company-settings-builder__intro-text">
          This is your Qoolo Admin Wizard. Here you can manage your Brands, Users, Contacts etc.
          This sequence will help you get started with your Qoolo journey. You will be guided
          through this step sequence once but you'll always be able to return and edit these
          settings.
        </p>
        <h2 className="company-settings-builder__step-subtitle">
          <span>Step 1: </span>
          <span>Set up your Company Structure!</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className="company-settings-builder__intro-text">
          Customize your Company Structure below. Here you will determine the number of levels of
          your company and their names. The company tree on the right will reflect these changes and
          there you will add your branches.
        </p>
        <div className="company-settings-builder__org-level-select" style={{alignItems:'center' }}>
          <InputNumber
            min={1}
            max={3}
            value={levelNumber}
            onChange={(value) => setLevelNumber(value)}
          />
          <Tooltip title="Please enter the number of levels in your company's organizational structure (e.g., if you have Global, Region, and Country, you would enter 3). Note that the maximum is 3. If you need additional levels, please reach out to support@qoolo.com for a custom solution.">
            <InfoCircleOutlined style={{ color: 'blue', marginLeft: '1em' }} />
          </Tooltip>
        </div>
        <div className="company-settings__level-fields">
          <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
            <Input
                className="level1-input"
                value={level1}
                onChange={(e) => setLevel1(e.target.value)}
                placeholder="Level 1 (Company)"
            />
            <Tooltip
                title="Please enter the name of the highest level in your organizational structure (e.g., Global)">
              <InfoCircleOutlined style={{color: 'blue', marginLeft: '1em'}}/>
            </Tooltip>
          </div>
            {levelNumber > 1 && (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                  <Input
                      className="level2-input"
                      value={level2}
                      onChange={(e) => setLevel2(e.target.value)}
                      placeholder="Level 2 (Country)"
                  />
                  <Tooltip
                      title="Please enter the name of the second level in your organizational structure (e.g., EU Region)">
                    <InfoCircleOutlined style={{color: 'blue', marginLeft: '1em'}}/>
                  </Tooltip>
                </div>
            )}
            {levelNumber > 2 && (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                  <Input
                      className="level3-input"
                      value={level3}
                      onChange={(e) => setLevel3(e.target.value)}
                      placeholder="Level 3 (State)"
                  />
                  <Tooltip
                    title="Please enter the name of the third level in your organizational structure (e.g., USA)">
                    <InfoCircleOutlined style={{color: 'blue', marginLeft: '1em'}}/>
                   </Tooltip>
                </div>
            )}
          </div>
          <div className="company-settings-builder__save-levels-button">
            {/*<Button*/}
            {/*  type="primary"*/}
            {/*  className="company-settings-builder__step-btn"*/}
            {/*  onClick={() => changeCompanyLevels(levelNumber, level1, level2, level3, client._id)}*/}
            {/*>*/}
            {/*  Save*/}
            {/*</Button>*/}
          </div>
          <div className="company-settings-builder__bottom-row">
            <Button
                type="primary"
                className="company-settings-builder__step-btn"
                onClick={() => changeCompanyLevels(levelNumber, level1, level2, level3, client._id)}
            >
              Save
            </Button>
            <Button
                id="company-settings_next-step"
                className="company-settings-builder__step-btn"
                type="primary"
                onClick={() => {
                  if (!completedStep) setCompletedStep(1);
                  setStep(2);
                }}
            >
              Next
            </Button>
          </div>
        </div>

        <Divider type="vertical" style={{height: 'auto'}}/>

        <div className="company-settings-builder__right-side">
        <ClientBranches
          treeData={treeData}
          client={client}
          treeDataChangeHandler={treeDataChangeHandler}
          treeBranchMoveHandler={treeBranchMoveHandler}
          treeDeleteDataHandler={treeDeleteDataHandler}
          setTreeData={setTreeData}
          level1={level1}
          level2={level2}
          level3={level3}
        />
      </div>
    </div>
  );
};

export default SettingsStepOrganisation;
