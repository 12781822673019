/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';
import { ReactComponent as Info } from "../../icons/info icon new.svg";
const formInit = {
  _id: null,
  name: '',
  therapeuticArea: [],
  therapeuticAreaCondition: []
};

const BrandForm = ({ isNew, data, onSubmit, SERVER_URL, createProcess = false }) => {
  const [form] = Form.useForm();
  const initialValues = { ...formInit, ...data };
  const [filteredConditions, setFilteredConditions] = useState([]);
  const [therapeuticArea, setTherapeuticArea] = useState([]);
  const [therapeuticAreaCondition, setTherapeuticAreaCondition] = useState([]);

  const [stateForRender, setStateForRender] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const onFinish = async (values, isNew2) => {
    onSubmit(values, isNew2);
    if(isNew2) form.resetFields();
  }

  useEffect(() => {
    if(!form.getFieldsValue().name || !form.getFieldsValue().therapeuticArea?.length || !form.getFieldsValue().therapeuticAreaCondition?.length){
      setDisabled(true)
    }
  }, [form.getFieldsValue()])


  const onTherapeuticAreaStateChange = (value) => {
    const filter = therapeuticAreaCondition.filter((c) => value.includes(c.therapeuticArea));
    setFilteredConditions(filter);

    if (value.length === 0) {
      form.setFieldsValue({ therapeuticAreaCondition: [] });
    } else {
      const validConditions = form.getFieldValue('therapeuticAreaCondition').filter(tc =>
        filter.some(f => f._id === tc)
      );
      form.setFieldsValue({ therapeuticAreaCondition: validConditions });
    }
  };

  const getTherapeuticsArea = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-for-brands`);
      setTherapeuticArea(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTherapeuticsAreaConditions = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-condition-for-brands`);
      setTherapeuticAreaCondition(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTherapeuticsArea();
    getTherapeuticsAreaConditions();
  }, []);

  useEffect(() => {
    if (initialValues.therapeuticArea.length > 0) {
      onTherapeuticAreaStateChange(initialValues.therapeuticArea);
    }
    form.setFieldsValue(initialValues);
  }, [initialValues.therapeuticArea, therapeuticAreaCondition, form]);


  return (
    <div className="panel">
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          onValuesChange={(changedValues, values) => {
            if(!values.name || !values.therapeuticArea?.length || !values.therapeuticAreaCondition?.length){
              setDisabled(true);
            } else {
              setDisabled(false);
            }
            setStateForRender((prev) => prev + 1);
          }}
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout="vertical"
          form={form}
        >
          {!createProcess && (
            <div className="modal-team__header" style={{ textAlign: 'center', marginBottom: '30px'}}>
              <h2 style={{ fontWeight: 600 }}>{isNew ? 'Add new' : 'Edit'} Brand</h2>
            </div>
          )}
         {isNew ? <div className="modal-team__input">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true, message: 'Please enter brand name!' }]}
                  name="name"
                >
                  <Input placeholder="Brand Name" suffix={<Info style={{marginRight: "2px"}}/>}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="genericName">
                  <Input placeholder="Generic Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="therapeuticArea"
                  rules={[
                    { required: true, message: 'Please select at least one therapeutic area' }
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Therapeutic Area'
                    allowClear
                    onChange={onTherapeuticAreaStateChange}
                    maxTagCount={1}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {therapeuticArea &&
                      therapeuticArea.map((t) => (
                        <Select.Option key={t._id} value={t._id}>
                          {t.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="therapeuticAreaCondition"
                  rules={[{ required: true, message: 'Please select at least one condition' }]}
                >
                  <Select
                    onChange={(value) => {
                      form.setFieldsValue({ therapeuticAreaCondition: value });
                    }}
                    mode='multiple'
                    placeholder='Medical Conditions'
                    allowClear
                    maxTagCount={1}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                    {filteredConditions &&
                      filteredConditions.map((c) => (
                        <Select.Option
                          key={c._id}
                          value={c._id}
                          id={c.name.toLowerCase().split(' ').join('_')}
                        >
                          {c.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div> :
            <div className="modal-team__input">
                <Form.Item
                  rules={[{ required: true, message: 'Please enter brand name!' }]}
                  name="name"
                >
                  <Input placeholder="Brand Name" suffix={<Info style={{marginRight: "2px"}}/>} />
                </Form.Item>
                <Form.Item name="genericName">
                  <Input placeholder="Generic Name" />
                </Form.Item>
                <Form.Item
                  name="therapeuticArea"
                  rules={[
                    { required: true, message: 'Please select at least one therapeutic area' }
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Therapeutic Area'
                    allowClear
                    onChange={onTherapeuticAreaStateChange}
                    maxTagCount={1}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {therapeuticArea &&
                      therapeuticArea.map((t) => (
                        <Select.Option key={t._id} value={t._id}>
                          {t.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="therapeuticAreaCondition"
                  rules={[{ required: true, message: 'Please select at least one condition' }]}
                >
                  <Select onChange={(value) => {
                    form.setFieldsValue({ therapeuticAreaCondition: value })
                  }} mode='multiple' placeholder='Medical Conditions' allowClear maxTagCount={1} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                    {filteredConditions &&
                      filteredConditions.map((c) => (
                        <Select.Option
                          key={c._id}
                          value={c._id}
                          id={c.name.toLowerCase().split(' ').join('_')}
                        >
                          {c.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
            </div>
          }
          <div className="company-settings-builder__save-levels-button">
            <Button
              style={{ width: !isNew ? '100%' : 'auto'}}
              type="primary"
              htmlType="submit"
              className="margin-top-20"
              id="add-update-brand-button"
              disabled={disabled}
            >
              {isNew ? 'Add' : 'Update'} brand
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BrandForm;
