import React from "react";
import { ReactComponent as Check } from '../../icons/ph_check.svg';
import { ReactComponent as Error } from '../../icons/ph_error.svg';
import { ReactComponent as Close } from '../../icons/ph_x-light.svg';

const UploadMessage = ({fileName, type, setUploadPercentage , inProgress}) => {
    return (
        <div className={`messages-wrapper messages-wrapper__${type}`}>  
            {type === 'success' &&
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span style={{ textAlign: 'left' }}>Your file {` `}
                        <span style={{color: '#39BB82', fontWeight: 600}}>
                            {fileName}{` `}
                       </span>
                       <span style={{ textAlign: 'left' }}>was uploaded successfully!</span>
                    </span>
                </div>
            }
            {type === 'progress' && <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <span style={{ textAlign: 'left' }}>Your file {` `}
                    <span style={{color: '#333333', fontWeight: 600, textAlign: 'left'}}>
                        {fileName}</span>{` `} <span style={{ textAlign: 'left' }}>is being uploaded.</span>
                </span>
            </div>}
            {type === 'error' &&
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
               <span style={{ textAlign: 'left' }}>We have encountered some {` `}
                   <span style={{ color: '#FB5E5E' }}>{fileName}</span>.
                   Please check the table on the right and implement the required edits to proceed {` `} is being uploaded.
               </span>
            </div>
            }
            {type === 'resolved' &&
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                   <span style={{textAlign: 'left'}}>All issues {` `}
                       <span style={{color: '#39BB82'}}>{fileName}</span>.
                       You can continue to the next step!
                   </span>
                </div>
            }
            <div style={{
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: setUploadPercentage && !inProgress ? 'pointer' : 'not-allowed'
            }}>
                {type === 'success' || type == 'resolved' ? <Check/> : type === 'error' ? <Error/> :
                    <Close onClick={() => {
                        if(!inProgress && setUploadPercentage) setUploadPercentage(0)
                    }}/>}
            </div>

        </div>
    )
}

export default UploadMessage;