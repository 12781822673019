/* eslint-disable no-unused-vars */
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import {Select, Input, Button, Form, Divider, Row, Col, Checkbox} from 'antd';
import countries from '../../lib/countries';
import {countryCodes} from "../../utils/countryCodes";
import { ReactComponent as Info } from "../../icons/info icon new.svg";
const formInit = {
  _id: null,
  therapeuticArea: null,
};

// const typeOfHCP = [
//   'Medical Doctor',
//   'Nurse Practitioner ',
//   'Nutritionist',
//   'Nursing Assistant',
//   'Nurse Midwife',
//   'Occupational Therapist',
//   'Pharmacy Technician',
//   'Physician Assistant ',
//   'Physical Therapist',
//   'Registered Nurse',
//   'Speech Pathologist'
// ];

const typeOfContact = [
  {
    userType: "HCP", contactType: "Physician",
  },
  {
    userType: "HCP", contactType: "Dentist",
  },
  {
    userType: "HCP", contactType: "Nurse / NP",
  },
  {
    userType: "HCP", contactType: "Optometrist",
  },
  {
    userType: "HCP", contactType: "Pharmacist",
  },
  {
    userType: "HCP", contactType: "Veterinarian",
  },
  {
    userType: "HCP", contactType: "Nurse",
  },
  {
    userType: "HCP", contactType: "Other",
  },
  {
    userType: "Non-HCP", contactType: "Corporate/Internal",
  },
  {
    userType: "Non-HCP", contactType: "Health Authority",
  },
  {
    userType: "Non-HCP", contactType: "Health Care Organization",
  },
  {
    userType: "Non-HCP", contactType: "Investor",
  },
  {
    userType: "Non-HCP", contactType: "Media / Public Relations",
  },
  {
    userType: "Non-HCP", contactType: "Partner",
  },
  {
    userType: "Non-HCP", contactType: "Patient / Consumer",
  },
  {
    userType: "Non-HCP", contactType: "Patient Advocacy Group",
  },
  {
    userType: "Non-HCP", contactType: "Payor",
  },
  {
    userType: "Non-HCP", contactType: "Other",
  }
]
const userType = [
    'HCP',
    'Non-HCP',
]

const ContactForm = ({ isNew, data, onSubmit, SERVER_URL, createProcess = false }) => {
  const [form] = Form.useForm();
  const initialValues = { ...formInit, ...data };
  const [therapeuticArea, setTherapeuticArea] = useState([]);

  const [typeOfUser, setTypeOfUser] = useState(isNew ? "" : data.type);
  const [disabled, setDisabled] = useState(false);
  const [stateForRender, setStateForRender] = useState(0);
  const [disabledSpeciality, setDisabledSpeciality] = useState(false);
  const onFinish = async (values, isNew2) => {
    onSubmit(values, isNew2);
    if(isNew2) form.resetFields();
  }
 
  const getTherapeuticsArea = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-for-brands`);
      setTherapeuticArea(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTherapeuticsArea();
  }, []);

  useEffect(() => {
    if (!form.getFieldsValue().firstName || !form.getFieldsValue().typeHCP){
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    if(!isNew && form.getFieldsValue().type === "non-hcp"){
      setDisabledSpeciality(true);
    } else if(!isNew) {
      setDisabledSpeciality(false);
    }
  }, [form.getFieldsValue()])

  useEffect(() => {
    if(isNew){
      if(form.getFieldsValue().typeHCP === "Dentist"){
        form.setFieldsValue({primarySpeciality: "Oral + Dental", secondarySpeciality: "Oral + Dental"});
      }
      if(form.getFieldsValue().typeHCP === "Optometrist"){
        form.setFieldsValue({primarySpeciality: "Ophthalmology", secondarySpeciality: "Ophthalmology"});
      }
      if(form.getFieldsValue().typeHCP === "Pharmacist" || form.getFieldsValue().typeHCP === "Veterinarian" || form.getFieldsValue().typeHCP === "Other"){
        form.setFieldsValue({primarySpeciality: "Other", secondarySpeciality: "Other"});
      }
      if(form.getFieldsValue().type === "Non-HCP"){
        setDisabledSpeciality(true);
      } else {
        setDisabledSpeciality(false);
      }
    }
    // if(form.getFieldsValue().typeHCP === "Physician" || form.getFieldsValue().typeHCP === "Nurse/NP"){
    //   form.setFieldsValue({primarySpeciality: "Other", secondarySpeciality: "Other"});
    // }
  }, [form.getFieldsValue()])

  return (
    <div className="panel">
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          onValuesChange={(changedValues, values) => {
            if (!values.firstName || !values.typeHCP){
              setDisabled(true);
            } else {
              setDisabled(false);
            }
            if(!values.typeHCP){
              form.setFieldsValue({ primarySpeciality: undefined, secondarySpeciality: undefined });
            } 
            if(!values.type) {
              form.setFieldsValue({ typeHCP: undefined, primarySpeciality: undefined, secondarySpeciality: undefined });
            }
            if(changedValues.type){
              form.setFieldsValue({ typeHCP: undefined })
            }
            if(changedValues.type && values.primarySpeciality && values.secondarySpeciality){
              form.setFieldsValue({ primarySpeciality: undefined, secondarySpeciality: undefined });
            }
            setStateForRender((prev) => prev + 1);
          }}
          layout="vertical"
          form={form}
        >
          {!createProcess && (
            <div className="modal-team__header" style={{ textAlign: "center", marginBottom: "30px" }}>
              <h2 style={{ fontWeight: 600 }}>{isNew ? 'Add new' : 'Edit'} contact</h2>
            </div>
          )}
          {isNew ? <div className="modal-team__input">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true, message: 'Please enter contact name!' }]}
                  name="firstName"
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item name="employer">
                  <Input placeholder="Employer" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="lastName">
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item name="employerCountry">
                  <Select placeholder="Employer Country" allowClear showSearch>
                    {countries &&
                      countries.map((c) => (
                        <Select.Option key={c.code} value={c.name}>
                          {c.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="type"
                >
                   <Select onChange={(value) => setTypeOfUser(value)} placeholder="User Type" allowClear showSearch>
                     {userType && userType.map((type, index) => (
                         <Select.Option key={index} value={type}>
                           {type}
                         </Select.Option>
                     ))}
                   </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                    name="typeHCP"
                    rules={[{ required: true, message: 'Please select HCP type' }]}
                >
                  <Select placeholder="Type of Contact" allowClear showSearch>
                    {typeOfContact &&
                        typeOfContact.filter((item) => item.userType.toLowerCase() === typeOfUser?.toLowerCase()).map((t, index) => (
                            <Select.Option key={index} value={t.contactType}>
                              {t.contactType}
                            </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="primarySpeciality">
                  <Select disabled={disabledSpeciality} placeholder="Primary Speciality" allowClear showSearch>
                    {therapeuticArea &&
                        therapeuticArea.map((t) => (
                            <Select.Option key={t._id} value={t._id}>
                              {t.name}
                            </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="secondarySpeciality">
                  <Select disabled={disabledSpeciality} placeholder="Secondary Speciality" allowClear showSearch>
                    {therapeuticArea &&
                        therapeuticArea.map((t) => (
                            <Select.Option key={t._id} value={t._id}>
                              {t.name}
                            </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="countryCode">
                  <Select placeholder="Country Code" allowClear showSearch>
                    {countries &&
                      countries.map((c) => (
                        <Select.Option key={c.code} value={c.dial_code}>
                          {c.dial_code}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    { required: false, message: 'Please enter phone number!' },
                    {
                      validator: (_, value) => {
                        if (value) {
                          const reg = /^[0-9+]{8,}$/g;
                          if (!reg.test(value))
                            return Promise.reject(new Error('Please enter valid phone number!'));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <Input placeholder="Phone Number" allowClear showSearch/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="email">
                  <Input placeholder="Email"/>
                </Form.Item>
              </Col>
            </Row>
          </div> :
          <div className="modal-team__input">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                    rules={[{ required: true, message: 'Please enter contact name!' }]}
                    name="firstName"
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="lastName">
                  <Input placeholder="Last Name" />
                </Form.Item>

              </Col>
            </Row>
            <Form.Item name="employer">
              <Input placeholder="Employer" />
            </Form.Item>
            <Form.Item name="employerCountry">
              <Select placeholder="Employer Country" allowClear showSearch>
                {countries &&
                    countries.map((c) => (
                        <Select.Option key={c.code} value={c.name}>
                          {c.name}
                        </Select.Option>
                    ))}
              </Select>
            </Form.Item>
            <Form.Item
                name="type"
            >
              <Select onChange={(value) => setTypeOfUser(value)} placeholder="User Type" allowClear showSearch>
                {userType && userType.map((type, index) => (
                    <Select.Option key={index} value={type}>
                      {type}
                    </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
                name="typeHCP"
                rules={[{ required: true, message: 'Please select HCP type' }]}
            >
              <Select placeholder="Type of Contact" allowClear showSearch>
                {typeOfContact &&
                    typeOfContact.filter((item) => item.userType.toLowerCase() === typeOfUser?.toLowerCase()).map((t, index) => (
                        <Select.Option key={index} value={t.contactType}>
                          {t.contactType}
                        </Select.Option>
                    ))}
              </Select>
            </Form.Item>
            <Form.Item name="primarySpeciality">
              <Select disabled={disabledSpeciality} placeholder="Primary Speciality" allowClear showSearch>
                {therapeuticArea &&
                    therapeuticArea.map((t) => (
                        <Select.Option key={t._id} value={t._id}>
                          {t.name}
                        </Select.Option>
                    ))}
              </Select>
            </Form.Item>
            <Form.Item name="secondarySpeciality">
              <Select disabled={disabledSpeciality} placeholder="Secondary Speciality" allowClear showSearch>
                {therapeuticArea &&
                    therapeuticArea.map((t) => (
                        <Select.Option key={t._id} value={t._id}>
                          {t.name}
                        </Select.Option>
                    ))}
              </Select>
            </Form.Item>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item name="countryCode">
                  <Select placeholder="Country Code" style={{width:'100%'}} allowClear showSearch>
                    {countryCodes.map((item, index) => (
                        <Select.Option key={index} value={item.calling_code}>{item.calling_code}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                    name="phone"
                    rules={[
                      { required: false, message: 'Please enter phone number!' },
                      {
                        validator: (_, value) => {
                          if (value) {
                            const reg = /^[0-9+]{8,}$/g;
                            if (!reg.test(value))
                              return Promise.reject(new Error('Please enter valid phone number!'));
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                    normalize={(value) => value.trim()}
                >
                  <Input placeholder="Phone Number" allowClear showSearch/>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="email">
              <Input placeholder="Email"/>
            </Form.Item>
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
              <span>Opt-in to Communication Channels</span><Info style={{marginLeft: "5px"}}/>
            </div>
            <div style={{ marginTop: "10px", display: "flex", justifyContent: "flex-start" }}>
              <Form.Item name={["optedIn", "sms"]} valuePropName="checked">
                <Checkbox>
                  SMS/MMS
                </Checkbox>
              </Form.Item>
              <Form.Item name={["optedIn", "email"]} valuePropName="checked">
                <Checkbox>
                  Email
                </Checkbox>
              </Form.Item>
            </div>

          </div>
          }
          <div className="company-settings-builder__save-levels-button">
          <Button
              style={{ width: !isNew ? '100%' : 'auto'}}
              id="add-contact-button"
              type="primary"
              htmlType="submit"
              className="margin-top-20"
              disabled={disabled}
            >
              {isNew ? 'Add' : 'Update'} contact
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
