export const countryCodes = [
    { "country": "Afghanistan", "calling_code": "+93" },
    { "country": "Albania", "calling_code": "+355" },
    { "country": "Algeria", "calling_code": "+213" },
    { "country": "American Samoa", "calling_code": "+1-684" },
    { "country": "Andorra", "calling_code": "+376" },
    { "country": "Angola", "calling_code": "+244" },
    { "country": "Anguilla", "calling_code": "+1-264" },
    { "country": "Antarctica", "calling_code": "+672" },
    { "country": "Antigua and Barbuda", "calling_code": "+1-268" },
    { "country": "Argentina", "calling_code": "+54" },
    { "country": "Armenia", "calling_code": "+374" },
    { "country": "Aruba", "calling_code": "+297" },
    { "country": "Australia", "calling_code": "+61" },
    { "country": "Austria", "calling_code": "+43" },
    { "country": "Azerbaijan", "calling_code": "+994" },
    { "country": "Bahamas", "calling_code": "+1-242" },
    { "country": "Bahrain", "calling_code": "+973" },
    { "country": "Bangladesh", "calling_code": "+880" },
    { "country": "Barbados", "calling_code": "+1-246" },
    { "country": "Belarus", "calling_code": "+375" },
    { "country": "Belgium", "calling_code": "+32" },
    { "country": "Belize", "calling_code": "+501" },
    { "country": "Benin", "calling_code": "+229" },
    { "country": "Bermuda", "calling_code": "+1-441" },
    { "country": "Bhutan", "calling_code": "+975" },
    { "country": "Bolivia", "calling_code": "+591" },
    { "country": "Bosnia and Herzegovina", "calling_code": "+387" },
    { "country": "Botswana", "calling_code": "+267" },
    { "country": "Brazil", "calling_code": "+55" },
    { "country": "Brunei", "calling_code": "+673" },
    { "country": "Bulgaria", "calling_code": "+359" },
    { "country": "Burkina Faso", "calling_code": "+226" },
    { "country": "Burundi", "calling_code": "+257" },
    { "country": "Cambodia", "calling_code": "+855" },
    { "country": "Cameroon", "calling_code": "+237" },
    { "country": "Canada", "calling_code": "+1" },
    { "country": "Cape Verde", "calling_code": "+238" },
    { "country": "Cayman Islands", "calling_code": "+1-345" },
    { "country": "Central African Republic", "calling_code": "+236" },
    { "country": "Chad", "calling_code": "+235" },
    { "country": "Chile", "calling_code": "+56" },
    { "country": "China", "calling_code": "+86" },
    { "country": "Colombia", "calling_code": "+57" },
    { "country": "Comoros", "calling_code": "+269" }
]
